<template>
    <b-card-code
      title="Payments"
      no-body
    >
        <!-- Login -->
        <b-modal id="modaljwt" title="Login" v-model="modalAction" hide-footer centered :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" size="lg">
        <b-form @submit="onSubmit">
            <b-form-group
            id="input_mail"
            label="Email address"
            label-for="input-mail"
            >
            <b-form-input
                id="input-mail"
                type="email"
                placeholder="Enter email"
                v-model="formJWT.username"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            id="input_pass"
            label="Password"
            label-for="input-pass"
            >
            <b-form-input
                id="input-pass"
                type="password"
                placeholder="Enter password"
                v-model="formJWT.password"
                required
            ></b-form-input>
            </b-form-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button class="ml-2" variant="outline-secondary" :to="linkLanding">Back to Landing</b-button>
        </b-form>
        </b-modal>
        <!-- Login -->
        <div>
            <b-alert
                :show="dismissCountDown"
                dismissible
                fade
                variant="warning"
                @dismiss-count-down="countDownChanged"
                v-model="showDismissibleAlert"
            >
            You have to select one or more table rows to create an invoice
            </b-alert>
        </div>
        
        <b-card-body>
            <div class="d-flex justify-content-between flex-wrap">
                <!-- sorting  -->
                <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelect"
                class="mr-1 mb-md-0 align-items-center"
                >
                <b-input-group
                    size="sm"
                >
                    <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    >
                    <template #first>
                        <option value="">
                        none
                        </option>
                    </template>
                    </b-form-select>
                    <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    >
                    <option :value="false">
                        Asc
                    </option>
                    <option :value="true">
                        Desc
                    </option>
                    </b-form-select>
                </b-input-group>
                </b-form-group>

                <!-- filter -->
                <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0 align-items-center"
                >
                <b-input-group size="sm">
                    <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                    filter-debounce=200
                    />
                    <b-input-group-append>
                    <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                    >
                        Clear
                    </b-button>
                    </b-input-group-append>
                </b-input-group>
                </b-form-group>
            </div>
        </b-card-body>
    
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <b-card-code
                        title=""
                        no-body
                        v-if="!modalAction"
                    >
                        <b-table
                            ref="selectableTable"
                            striped
                            hover
                            responsive
                            class="position-relative"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :items="items"
                            :fields="fields"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                            :busy="isBusy"
                            >
                            <template #table-busy>
                              <div class="d-flex align-items-center justify-content-around my-2">
                                <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                                <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                                <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
                              </div>
                            </template>
                            <template #cell(check_tr)="data">
                              <b-form-checkbox
                                v-if="!data.item.valid_until"
                                :id="'checkbox'+data.item.quote_id"
                                :name="'checkbox'+data.item.quote_id"
                                @change="getInvoiceData(data.item.quote_id, $event,  data.item)"
                              >
                              </b-form-checkbox>
                              <b-form-checkbox
                                v-else
                                :id="'checkbox'+data.item.quote_id"
                                :name="'checkbox'+data.item.quote_id"
                                :disabled="true"
                              >
                              </b-form-checkbox>
                            </template>
                            <template #cell(invoice_tr)="data">
                              <b-dropdown :id="'dropdown_'+data.item.quote_id" size="sm" text="Invoice" class="m-md-2" v-if="data.item.valid_until">
                                  <b-dropdown-item v-if="data.item.invoice_tr" :href="'https://www.vivapayments.com/web2?ref='+data.item.invoice_tr" target="_blank">Checkout</b-dropdown-item>
                                  <b-dropdown-item v-if="data.item.tr_token" :href="'https://quote.safetravel.today/invoice.php?t='+data.item.tr_token" target="_blank">Invoice</b-dropdown-item>
                              </b-dropdown>
                              <div v-else> - </div>
                            </template>
                        </b-table>
            
                        <!-- buttons -->
                        <b-card-body class="demo-inline-spacing pt-0">
                            <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="createInvoice()"
                            >
                            Create invoice
                            </b-button>
                            <div v-show="invoiceDropState">
                            <b-dropdown id="dropdown-invoice" text="Invoice" class="m-md-2">
                                <b-dropdown-item :href="checkoutLink" target="_blank">Checkout</b-dropdown-item>
                                <b-dropdown-item :href="invoiceLink" target="_blank">Invoice</b-dropdown-item>
                            </b-dropdown>
                            </div>
                        </b-card-body>
            
                        <template #code>
                            {{ codeRowSelectSupport }}
                        </template>
                    </b-card-code>
                    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

                        <!-- page length -->
                        <b-form-group
                            label="Per Page"
                            label-cols="6"
                            label-align="left"
                            label-size="sm"
                            label-for="sortBySelect"
                            class="text-nowrap mb-md-0 mr-1 align-items-center"
                        >
                            <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            inline
                            :options="pageOptions"
                            />
                        </b-form-group>

                        <!-- pagination -->
                        <div>
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mb-0 align-items-center"
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                    </b-card-body>
                </div>
            </div>
        </div>
        <template #code>
        {{ codeKitchenSink }}
        </template>
    </b-card-code>
  </template>
  
  <script>
  import jwt from 'jsonwebtoken'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import moment from 'moment'
  import {
    BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BAlert, BFormCheckbox, BSpinner, BForm
  } from 'bootstrap-vue'
  import { codeKitchenSink } from '../table/bs-table/code'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import { codeRowSelectSupport } from '../table/bs-table/code'
  
  export default {
    components: {
      BCardCode,
      BTable,
      BAvatar,
      BBadge,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCardBody,
      BDropdown,
      BDropdownItem,
      BAlert,
      BFormCheckbox,
      BSpinner,
      vSelect,
      BForm
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        formJWT: {
            username: '',
            password: '',
        },
        token_id:null,
        jwt:null,
        modalAction: true,
        invoiceArr: [],
        isBusy: true,
        modes: ['multi', 'single', 'range'],
        perPage: 5,
        pageOptions: [3, 5, 10],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'valid_until',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'check_tr', label: 'Check', sortable: true },
          { key: 'transaction_ids', label: 'Transaction Ids', sortable: true },
          { key: 'quote_id', label: 'Quote ID', sortable: true },
          { key: 'customer_name', label: 'Customer Name', sortable: true },
          { key: 'email', label: 'Email', sortable: true },
          { key: 'sold_by', label: 'Sold By', sortable: true },
          { key: 'policy_type', label: 'Policy Type', sortable: true },
          { key: 'full_amount', label: 'Full Amount', sortable: true },
          { key: 'invoice_tr', label: 'Invoice', sortable: true },
          { key: 'valid_until', label: 'Valid Until', sortable: true },
          { key: 'issued', label: 'Issued', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
        /* eslint-disable global-require */
        status: [{
          1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
        },
        {
          1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
        }],
        codeKitchenSink,
        selectMode: 'multi',
        selected: [],
        codeRowSelectSupport,
        transaction_ids: [],
        trid: null,
        checkoutLink: null,
        invoiceLink: null,
        dismissSecs: 5,
        dismissCountDown: 5,
        showDismissibleAlert: false,
        invoiceDropState: false,
        elem: null,
      }
    },
    computed: {
      linkLanding() {
        return this.$store.state.app.agent!='1dbaa403b94ccdd1991c3afd511858a5' ? { name: 'landing', query: { agent: this.$store.state.app.agent}} : { name: 'landing'}
      },
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
      },
    },
    methods: {
      scrollToTop() {
        window.scrollTo(0,0);
      },
      async onSubmit(event) {
        event.preventDefault()
        const crd = this.formJWT
        try {
            await fetch('https://api-quote.safetravel.today/v2/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(crd)
            })
            .then((response)=> {
            return response.json();
            })
            .then((res)=> {
            if (res.jwt) {
                const decoded = jwt.decode(res.jwt, { complete: true });
                this.token_id = decoded?.payload?.serial ?? null
                this.jwt = res?.jwt ?? null
                this.modalAction=false
                this.get_payments()
            } else {
                this.formJWT.username=""
                this.formJWT.password=""
            }
            })
        } catch(e) {
            // TypeError: Failed to fetch
            console.warn('There was an error on fetch login', error);
        }
      },
      async get_payments() {
        const data = {
          token: this.token_id,
          jwt: this.jwt
        }
        const url= {
          local: 'http://localhost:8080/v2/app/get_payments',
          prod: 'https://api-quote.safetravel.today/v2/app/get_payments'
        };
        try {
          await fetch(url.prod, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=>{
            if (res === 'not_valid') {
              window.location.href = '/landing';
              return;
            }
            if (res) {
              res.forEach((r)=> {
                this.items.push({
                  transaction_ids: r?.tr_transactionID,
                  quote_id: r?.tr_quote_id,
                  email: r?.tr_email,
                  sold_by: r?.tr_sold_by,
                  issued: r?.tr_issued_at,
                  customer_name: r?.tr_customer_name,
                  policy_type: r?.tr_policyType,
                  full_amount: r?.tr_viva_amount,
                  valid_until: r?.expires,
                  invoice_tr: r?.tr_invoice,
                  tr_token: r?.tr_token,
                })
              });
            }
          })
          .then(()=> {
            this.isBusy = false;
            this.totalRows = this.items.length;
            this.sortBy = 'valid_until';
          })
        } catch (err) {
          console.warn(err)
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
      async createInvoice() {
        this.showDismissibleAlert=false
        const idsArr=[];
        const tridArr=[];
        this.invoiceArr.forEach(e=> {
          idsArr.push(Number(e.transaction_ids))
          tridArr.push(e.tr_token)
        })
        const trid = tridArr.sort().slice(-1).pop();
        const data = {
          token: this.token_id,
          env: 'production',
          transaction_ids: idsArr
        }
        // check if array empty
        if (idsArr.length > 0 && tridArr.length > 0) {
            try {
                await fetch('https://api-quote.safetravel.today/v2/app/payment-order/generate', {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                })
                .then((response)=>response.json())
                .then((res)=>{
                        const checkoutUrl = res.payment_order.checkoutUrl.split('?ref=')[1];
                        const amount = res.payment_order.paymentOrder.amount / 100;
                        const tExpired = moment().add(3, 'hours').format("YYYY-MM-DD hh:mm:ss");
                        this.invoiceArr.forEach(e=> {
                        e.invoice_tr=checkoutUrl
                        e.valid_until= tExpired
                        e.tr_token = trid
                        e.full_amount = amount
                        })
                })
            } catch (err) {
                console.warn(err)
            }
        } else {
            this.showDismissibleAlert=true
            this.scrollToTop()
        }
        
        // go to first page
        this.currentPage = 1
      },
      getInvoiceData(ch, elem , obj) {
        if (elem) {
          this.invoiceArr.push(obj);
        } else {
          this.invoiceArr = this.invoiceArr.filter(e => e.quote_id !== ch)
        }
      },
    },
  }
  </script>
  